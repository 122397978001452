function initialize() {
  var center = new google.maps.LatLng(-25.4603361,-49.300691);

  var mapOptions = {
    center: center,
    zoom: 16,
    styles: [{
      featureType: 'poi.business',
      elementType: 'all',
      stylers: [
        {visibility: 'off'}
      ]

    }]
  };

  var map = new google.maps.Map(document.getElementById('contact-map'),
    mapOptions);

  var marker = new google.maps.Marker({
    position: center,
    map: map,
    title:"LeG Administradora"
  });
}

google.maps.event.addDomListener(window, 'load', initialize);