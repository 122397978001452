

require('../../bower_components/bootstrap/js/scrollspy');

// Auto changes the highlighted item in the menu as we scroll
// down the page
$('body').scrollspy({
  target: '.section-header',
  offset: 30
});



// Scroll smoothly to a section
$("a[href^='#']").click(function(e) {
  e.preventDefault();
  var elementId = e.currentTarget.getAttribute('href');
  var pos = $(elementId).offset().top - 30;

  if (pos < 0) {
    pos = 0;
  }

  console.log(elementId);
  console.log(pos);

  $('html, body').animate({
    scrollTop: pos
  }, 1000, 'swing', function () {
    if (pos) {
      window.location.hash = elementId;
    } else {
      window.location.hash = '';
    }
  });
});